@font-face {
  font-family: Nexa;
  src: url(Nexa-Bold.ttf);
}

body {
  height: 100vh;
  background-image: linear-gradient(#FF6069, #EB3B5A);
  background-image: linear-gradient(color(display-p3 0.807 0.179 0.326 / 1), color(display-p3 0.896 0.209 0.33 / 1));
  overflow: none;
  color: white;
  margin: 0;
  padding:0;
  font-family: Nexa, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html {
  height: 100vh;
}

h1 {
  text-align: center;
  font-size: 80px;
  margin: 0;
}


#too-few-players {
  animation: FadeInText 0.5s forwards cubic-bezier(.33,0,.4,.99);
  transform-origin: bottom center;
}

.fadeInText {
  animation: FadeInText 0.5s forwards cubic-bezier(.33,0,.4,.99);
  transform-origin: bottom center;
}

@keyframes FadeInText {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}