

p {
    text-align: center;
    font-size: 37px;
    font-family: 'Red Hat Text', sans-serif;
    font-weight: 500;
    margin: auto;
    width: 60%;
    margin-top: 2%;
}

#header {
    height: 10vh;
    margin: auto;
    width: 100%;
}

#question {
    height: 60%;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    #question-game-container p {
    font-size: 20px;
    line-height: 1.6;
    font-size: 24px;
    width: 100%;
    height: 70%;
    margin-top: 20px;

    }
    #question-game-container h1 {
        font-size: 44px;
    }
    #header {
        height: 0;
        margin: initial;
    }
}

#question-game-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 100vw;
    padding: 30px;
    box-sizing: border-box;
}

#question-game-container .primary {
    position: fixed;
    bottom: 10%;
}

.explainer {
    font-weight: 400;
    font-size:20px;
    width: 80%;
    display: block;
    margin: auto;
    margin-top: 5%;
}