.switching-card {
  position: relative;
  width: 320px;
  height: 200px;
}

.switching-card.switching {
  z-index: 10;
}


.switching-card-content {
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 12px;
  z-index: 2;
  user-select: none;
}

.switching-card:not(.switching) .switching-card-content:hover {
  cursor: pointer;
  border-color: #e0e0e0;
}

.switching-card-content.next {
  z-index: 1;
}

.switching-card.switching .switching-card-content {
  transform-origin: 5% 95%;
  animation: switching-card-content-disappear 750ms linear;
}

.switching-card.switching .switching-card-content.next {
  transform-origin: 95% 5%;
  animation-name: switching-card-content-appear;
}

.switching-card.no-next-content .switching-card-content {
  transform-origin: center;
  animation: switching-card-content-bounce 350ms linear;
}

@keyframes switching-card-content-appear {
  0% {
    animation-timing-function: cubic-bezier(.33,0,.4,.99);
    transform: scale(0.8);
    filter: brightness(90%);
    opacity: 0; 
  }

  49.5% {
    z-index: 1;
  }

  50% {
    transform: translateX(50%) rotate(5deg) scale(0.9);
    animation-timing-function: cubic-bezier(0.33 , 0 , 0.4 ,0.99);
    animation-duration: 2s;
    z-index: 2;
  }



  100% {
    transform: none;
    z-index: 2;
    opacity: 1;
  }
}

@keyframes switching-card-content-disappear {
  0% {
    animation-timing-function: cubic-bezier(.33,0,.4,.99);
  }

  49.5% {
    z-index: 2;
  }

  50% {
    transform: translateX(-50%) rotate(-5deg) scale(0.9);
    animation-timing-function: cubic-bezier(.33,0,.4,.99);
    z-index: 1;
    opacity: 1;
  }


  100% {
    transform: scale(0.8);
    filter: brightness(90%);
    opacity: 0;
    z-index: 1;
  }
}

@keyframes switching-card-content-bounce {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transform: none;
  }

  50% {
    transform: scale(1.05);
    animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
  }
}
