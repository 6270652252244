#ring-of-fire-container .primary {
    position: fixed;
    bottom: 8%;
    left: 50%;
    z-index: 300;
    transform: translateX(-50%);
    cursor: pointer;
}

#ring-of-fire-container .primary:disabled {
    pointer-events: none;
    opacity: 0.7;
}

#player {
    display:block;
}

#cards-container {
        margin: auto;
        margin-top:3%;
        height: 35vh;
        box-sizing: border-box;
        margin-bottom: 100px !important;
        box-shadow: rgba(69, 0, 0, 0.2) 0px 7px 29px 0px inset;
        width: 75vw;
        padding: 20px;
        border-radius: 30px;
        max-height: 40vh;
        display: flex;
        position: relative;
}

.card {
    border-radius: 10px;
    z-index: 2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    color: #EB3B5A;
    border-radius: 20px;
    box-sizing: border-box;
    z-index: 200;
    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    position: absolute;
    margin: auto;
    height: 50vh;
    width: 40vw;
    transform: translateY(-30%);
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
/*
.card:not(.flipped)::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}*/

.card p {
    font-size: 20px;
    margin: auto;
}

.card.flipped::after {
    content: '';
    border-radius: 10px;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    display:block;
    position: absolute;
    background-color: black;
    background: url("./mixio-card.png");
    background-size: cover;
    background-position: center;
    z-index: 3;
}

@media only screen and (max-width: 768px) {
    #cards-container {
        width: 85vw;
    }
    .card-holder {
        width: 100% !important;
    }
    .card {
        width: 86% !important;
        height: 60vh !important;
        top: -30%;
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: translateY(0);
    }
    .card:hover {
        z-index: 999999 !important;
    }
    .card-holder.flipped {
        z-index: 999999 !important;
    }
    .card:not(.flipped) {
        top: 5% !important;
        z-index: 999999 !important;
    }
    .card.flipped  {
        width: 88%;
        height: 18vh !important;
        box-shadow: rgba(93, 50, 50, 0.2) 0px 13px 27px -5px, rgba(42, 5, 5, 0.2) 0px 8px 16px -8px;
    }
    #cards-container {
        flex-direction: column;
        height: 70vh;
        max-height: none;
    }
    #ring-of-fire-container .primary {
        display: none;
    }
}
    

.card.flipped {
    transform: rotateY(180deg);
    position: absolute;
    width: 29%;
    height: 200px;  
    margin: 1%;
    z-index: 1;
    top: auto;
}

.color-icon {
    width: 50%;
    max-width: 100px;
    display: block;
    margin: auto;
}

.card-holder {
    width: 30%;
    margin: 1%;
    display: inline-block;
    height: 100%;
    position: static;
    /*animation: fadeZoomIn 1s 1s forwards cubic-bezier(.33,0,.4,.99);*/
}

.card-holder.flipped {
    z-index: 9999 !important;
}

/*
@keyframes fadeZoomIn {
    0% {
        /*transform: scale(0.8);
        opacity:0;
    }

    100% {
        /* transform: scale(1);
        opacity: 1;
    }
}*/