.App {
  text-align: center;
}

html {
  background-color: #FF6069;
  background-color:color(display-p3 0.807 0.179 0.326 / 1);
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position:fixed;
}

* {
  text-decoration: none !important;
}

#logo {
  margin: 0 auto;
  margin-top: 5%;
  width: 100px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  /*
  position: fixed;
  top: 0;
  margin: 100px 50%;
  transform: translateX(-50%);
  */
}

button {
  font-family: Nexa, sans-serif;
  padding: 20px 50px;
  background-color: white;
  border: none;
  border-radius: 30px;
}

button.primary {
  font-family: Nexa, sans-serif;
  font-size: 18px;
  padding: 20px 60px;
  color: white;
  background-color: #00D369;
  transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1)
}

button.primary:hover {
  background-color: #01b058;
}

.add-player {
  color: black !important;
}

#player-modal {
  padding: 40px;
  padding-bottom: 0;
  z-index: 999;
  position: fixed;
  width: 35vw;
  top: 8vh;
  left: 50%;
  background-color: white;
  transform: translateX(-50%);
  color: black;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
 margin-left: auto;
 margin-right: auto;
 margin-top: auto;
 margin-bottom:auto;
 text-align: center;
  overflow: auto;
  box-sizing: border-box;
  height: 80vh;
}

#player-modal::-webkit-scrollbar {
  display: none;
}

.player-modal-list {
  padding: 10px;
}

#player-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
}

#leave-game-button {
  position: absolute;
  top: 10px;
  left: 10px;
}

#player-modal-play:disabled {
  opacity: 0.7;
  pointer-events: none;
}


#player-modal-button, #leave-game-button {
  padding: 10px;
  color:white;
  font-size: 18px;
  background-color: #00000000;
}

.remove-player {
  background-color: #EB3B5A;
  color: white;
  padding: 10px;
}

.add-player {
  font-size: 15px;
}

#overlay {
  top:0;
  z-index: 800;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #00000080;
}

.btn-box {
  display: inline-block;
}

input {
  border: none;
  font-size: 16px;
  border-bottom: 1px solid lightgray;
  font-family: Nexa, sans-serif;
  padding: 15px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  #player-modal {
    width: 85vw;
    height: 85vh;
    padding: 25px;
    overflow-y: scroll;
  }

  #logo {
    margin-top: 8%;
  }

  #player-modal input {
    width: 65%;
    margin-right: 2%;
  }
  body {
    height: 100vh;
  }

}

#player-actions {
  margin: auto;
  margin-top: 30px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background-color: white;
  box-shadow: rgba(122,37,47, 0.25) 0px 25px 50px -12px;
  width: 100%;
  padding-bottom: 40px;
  padding-top: 20px;
  border-radius: 30px 30px 30px 30px;
}

#player-modal-list {
  padding: 40px;
}


#finish-game-view {
top: 20vh;
left: 50vw;
transform: translateX(-50%);
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
position: fixed;
height: 65vh;
width: 80vw;
background-image: linear-gradient(#FF6069, #EB3B5A);
border-radius: 10px;
box-shadow: rgba(122,37,47, 0.25) 0px 25px 50px -12px;
}

#finish-game-view h1 {
  margin-bottom: 10%;
}





/* SELECT GAME VIEW */


.game-link {
  margin: 0 2%;
}

#game-select-container {
  margin: auto;
  margin-top: 10%;
  box-sizing: border-box;
  margin-bottom: 100px !important;
  box-shadow: rgba(69, 0, 0, 0.2) 0px 7px 29px 0px inset;
  width: 75vw;
  padding-top: 30px;
  padding-bottom:30px;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.game-option {
  padding: 15px;
  margin: 8% 2%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
  width: 200px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .game-option {
    margin: 5% auto;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}
  #game-select-container {
   padding-top: 35px;
   display: block;
   height: 71vh;
   overflow: scroll;
}
 #game-select-container::-webkit-scrollbar {
  display: none;
}

}
	

.game-icon {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: auto;
}

.game-name {
  color: #EB3B5A;
  text-align: center;
  text-decoration: none;
}

#game-select-container a {
  padding: 0;
}